import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import ImageWithText from '@interness/web-core/src/components/media/ImageWithText/ImageWithText';
import MemberSearchBar from '../../components/MemberSearch/MemberSearchBar';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "adt-header"}) {
      media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1980, maxHeight: 750, cropFocus: CENTER, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const Separator = makeShortcode("Separator");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Carousel mdxType="Carousel">
    {props.data.headerImages.media.map(image => <ImageWithText key={image.file.localFile.name} mdxType="ImageWithText">
        <div className='int-text'>
          <b>Arbeitsgemeinschaft Deutscher Tierheilpraktiker</b>
          <p style={{
            fontWeight: 'bold'
          }}>im FVDH e.V.</p>
        </div>
        <Img fluid={image.file.localFile.childImageSharp.fluid} mdxType="Img" />
      </ImageWithText>)}
    </Carousel>
    <MemberSearchBar adt mdxType="MemberSearchBar" />
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <div style={{
        color: '#172C7F',
        fontStyle: 'italic',
        fontSize: '1.2rem'
      }}>
        <h1>{`Allgemeine Informationen`}</h1>
        <p>{`Der führende Ansprechpartner für Tierheilpraktiker und Tierheilpraktikeranwärter, sowie für Informationen zur Tierheilpraktiker-Ausbildung`}</p>
        <Separator mdxType="Separator" />
        <p>{`Sehr geehrte Kolleginnen und Kollegen, liebe Besucher unserer Website,`}</p>
        <p>{`im Namen des Vorstandes, des Präsidiums sowie der Landesleiter der ADT im FVDH möchten wir Sie auf unseren Internetseiten herzlich willkommen heißen.`}</p>
        <p>{`Tradition und Erhaltung von Erfahrung und Werten, verbunden mit den Erkenntnissen moderner Zeit, sind Aufgaben und Verpflichtungen eines Berufsverbandes, dessen Ziel es ist, den kranken Tieren zu helfen.`}</p>
        <p>{`Unser Berufsverband verfolgt genau diese Ziele und sichert seinen Mitgliedern über alle Verbandsinteressen hinweg die Ausübung der Tierheilkunde in einem freien Berufsbild. Als einer der mitgliederstärksten Berufsverbände in Deutschland setzen wir Maßstäbe für unseren Berufsstand.`}</p>
        <p>{`Unser erklärtes Ziel ist ein Optimum an Aus- und Weiterbildung sowie eine bestmögliche Service- und Qualitätssicherung für unsere praktizierenden Tierheilpraktiker/innen. Freiheit in der Berufsausübung, die Öffnung für jeden ernsthaft Bemühten, und Kooperation über die Verbandsgrenzen hinaus sind festgeschriebene Gesetze aller Verantwortlichen in unserem Verband.`}</p>
        <p>{`Unsere Stärken liegen in der Teamarbeit.`}</p>
        <p>{`Als kompetente Berufsorganisation Ihres Vertrauens, auf Landes- sowie Bundesebene, sind wir ständig bemüht, für Sie die Therapievielfalt in Ihren Tierheilpraktikerpraxen zu sichern und die Vielfalt der naturheilkundlichen Mittel zu bewahren.`}</p>
        <p>{`Im ständigen Dialog mit den Veterinärämtern in den Ländern und der Bundesregierung vertreten wir unseren Berufsstand für Ihre Interessen und Belange.`}</p>
        <p><em parentName="p"><strong parentName="em">{`Ihr FVDH Vorstand`}</strong></em></p>
        <p>{`Siegfried H. W. Schierstedt `}<br />{`
Matthias Mertler`}</p>
      </div>
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      